import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Button } from "reactstrap";
import { getAllUsersApi } from "../../api/users/usersApi/userProfileApi";
import { getAllRolesApi } from "./rolesapi/rolesApi";
const options = [
  { value: "chocolate", label: "Chocolate" },
  { value: "strawberry", label: "Strawberry" },
  { value: "vanilla", label: "Vanilla" },
];
function AssignRole() {
  const [allUsers, setAllUsers] = useState([]);
  const [allRoles, setAllRoles] = useState([]);
  const [allUsersDropdown, setAllUsersDropdown] = useState();

  useEffect(() => {
    initialFunction();
  }, []);

  const initialFunction = () => {
    getAllUsers();
    getAllRoles();
  };

  const getAllUsers = async () => {
    const responce = await getAllUsersApi();
    console.log(responce);
    if (responce.success) {
      const filterData = responce.data.map((item) => {
        return {
          value: item.user_id,
          label: item.user_name,
        };
      });
      setAllUsers(filterData);
      console.log("alluser---", filterData);
    }
  };

  const getAllRoles = async () => {
    const responce = await getAllRolesApi();
    if (responce.success) {
      console.log("allRoler---", responce);
      const filterData = responce.data.map((item) => {
        return {
          value: item.role_id,
          label: item.role_name,
        };
      });
      setAllRoles(filterData);
      console.log("allRoler---", responce);
    }
  };
  return (
    <div>
      <div>
        <div>
          <h5 className="my-3">Select User</h5>
          <Select className="w-25" options={allUsers} />
        </div>
        <div>
          <h5 className="my-3">Assign Role</h5>
          <Select className="w-25" options={allRoles} />
        </div>
        <Button className="mt-2" color="primary" title="Save Role">
          {" "}
          Save
        </Button>
      </div>
    </div>
  );
}

export default AssignRole;
