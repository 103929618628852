import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import { Container } from "react-bootstrap";
import { MdOutlineAddCircle } from "react-icons/md";
import DotLoader from "../loader/DotLoader";
import { Select } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../constant/Constant";
import "../../styles/tableDataGrid.css";

const columns = [
  {
    field: "order_number",
    headerName: "ORDER ID",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
    // hide: false,
  },
  {
    field: "order_date",
    headerName: "DATE",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
  {
    field: "customer_name",
    headerName: "CUSTOMER",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
  {
    field: "hospital_name",
    headerName: "HOSPITAL",

    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
  {
    field: "customer_area_name",
    headerName: "SET",

    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
  {
    field: "customer_areame",
    headerName: "STATUS",

    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
];
function OrderHome() {
  const navigate = useNavigate();
  return (
    <Container>
      {false ? (
        <DotLoader />
      ) : (
        <div>
          <div className="d-flex justify-content-end mb-5 mt-2">
            {/* <div className="w-40 mx-4">
              <Select placeholder="Filter Branch" className="w-100 " />
            </div> */}

            <MdOutlineAddCircle
              style={{
                color: Colors.OrthoColor,
                height: 40,
                width: 40,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/orders/new-order");
              }}
            />
          </div>

          <h3 className="mb-4">Orders</h3>

          <div className="container-div">
            <DataGrid
              onCellClick={(cell) => {
                console.log(cell.field);
                if (cell.field === "customer_first_name") {
                  console.log("cell ", cell);
                  //   handleCustomer(cell.id);
                  // Custom logic for clicking the "Name" column
                }
              }}
              //   loading={loader}
              //   rows={allCustomers}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              sx={{
                boxShadow: 2,

                "& .MuiDataGrid-columnHeaders": {
                  position: "sticky",
                  top: 0,
                  zIndex: 1100,
                  color: Colors.BlackLight,
                  boxShadow: "3px 3px 10px -3px #dddddd",
                  "& .MuiDataGrid-menuIcon": {
                    visibility: "visible",
                    width: "auto",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    opacity: "1 ! important",
                    visibility: "visible ! important",
                  },
                  "& .MuiDataGrid-iconSeparator": {
                    opacity: "1 !important", // Test other values
                    visibility: "visible !important", // Check if it's being overridden
                    display: "block !important", // Or try toggling with "none"
                    color: "grey",
                  },
                  fontFamily: "'Roboto', sans-serif",
                },

                "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
                  {
                    outline: "none",
                    border: "none",
                  },
                "& .MuiDataGrid-cell": {
                  overflow: "hidden",
                },

                "& .MuiDataGrid-cell[data-field='customer_first_name']": {
                  color: Colors.LinkColor,
                  cursor: "pointer",
                },
                "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                  border: "none",
                },
              }}
            />
          </div>
        </div>
      )}

      {/* <Outlet></Outlet> */}
    </Container>
  );
}

export default OrderHome;
