import { BASE_URL } from "../../../constant/Constant";

const getAllHospitalApi = async () => {
  try {
    console.log("--------------", localStorage.getItem("token"));
    const responce = await fetch(
      `${BASE_URL}/customer-service/hospital/get_all`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

const getHospitalDetailApi = async (hospitalId) => {
  try {
    const responce = await fetch(`${BASE_URL}/customer-service/hospital/get`, {
      method: "GET",
      headers: {
        Authorization: localStorage.getItem("token"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

export { getAllHospitalApi, getHospitalDetailApi };
