import React from "react";
// import { useLocation, useRoutes } from "react-router-dom";

function Home() {
  // const { state } = useLocation();
  // console.log("p--", state);
  return <div>Home</div>;
}

export default Home;
