export const Colors = {
  OrthoColor: "#0f3270",
  LinkColor: "#408DFB",
  BlackLight: "#343a40",
};

// export const BASE_URL = "http://192.168.1.53:8082";
// export const BASE_URL = "http://192.168.1.75:8082";
// export const BASE_URL = "http://192.168.1.75:8765";
export const BASE_URL = "http://localhost:8765";

// export const BASE_URL = "http://192.168.1.60:8765";
// export const BASE_URL = "http://13.127.210.78:8765";
export const SIZE = {
  Width: 30,
  Height: 30,
};
