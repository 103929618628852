import { BASE_URL } from "../../constant/Constant";

const getAllBranchApi = async () => {
  try {
    const responce = await fetch(
      `${BASE_URL}/location-service/branch/get_all`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

export { getAllBranchApi };
