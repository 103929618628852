import { BASE_URL } from "../../../constant/Constant";

const getCustomerProfileApi = async (id) => {
  try {
    const responce = await fetch(
      `${BASE_URL}/customer-service/customer/get/${id}`,
      {
        method: "GET",
        headers: {
          // Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

const getAllCustomersApi = async (id) => {
  try {
    console.log(
      "---------Customer All----------",
      localStorage.getItem("token")
    );
    console.log(`${BASE_URL}/customer-service/customer/get_all`);
    const responce = await fetch(
      `${BASE_URL}/customer-service/customer/get_all`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

const getAllCustomersPageApi = async (page, limit) => {
  try {
    console.log(
      "---------Customer All----------",
      localStorage.getItem("token")
    );
    console.log(
      `${BASE_URL}/customer-service/customer/get_page?startIndex=${page}&endIndex=${limit}`
    );
    const responce = await fetch(
      `${BASE_URL}/customer-service/customer/get_all`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

const getCreateCustomerDropdownListApi = async () => {
  try {
    console.log("--------------", localStorage.getItem("token"));
    const responce = await fetch(
      `${BASE_URL}/customer-service/customer/dropdown_list`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

const getCustomerDetailsApi = async (id) => {
  try {
    const responce = await fetch(
      `${BASE_URL}/customer-service/customer/get/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

const customerCommentsUpdate = async (comments, customer_id) => {
  const user_id = localStorage.getItem("userid");
  try {
    const response = await fetch(
      `${BASE_URL}/customer-service/customer/comments/update/${customer_id}`,
      {
        method: "PUT",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(comments),
      }
    );

    // Read the response as text
    const data = await response.text();

    return data;
  } catch (error) {
    console.log("Api Error", error);
    return null;
  }
};

export {
  getCustomerProfileApi,
  getAllCustomersApi,
  getCreateCustomerDropdownListApi,
  getCustomerDetailsApi,
  customerCommentsUpdate,
  getAllCustomersPageApi,
};
