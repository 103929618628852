import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Container,
  Tabs,
  Tab,
  Modal,
  Spinner,
  Toast,
} from "react-bootstrap";
import {
  newUserCreateApi,
  userEmailAvailableApi,
} from "../../api/users/usersApi/newUserCreateApi";
import { ToastContainer, toast, Bounce } from "react-toastify";
import { getAllBranchApi } from "../../api/branch/branchApi";
import Select from "react-select";
import { getAllRolesApi } from "../roles/rolesapi/rolesApi";

const inputData = { label: "", value: "" };

const NewUser = () => {
  // State Start------------------------------------
  const [emailAvailablityLoader, setEmailAvailablityLoader] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [formData, setFormData] = useState({
    username: "",
    email: "",
  });

  const user_email = {
    email: formData.email,
  };

  const [dropdownBranch, setDropdownBranch] = useState([]);
  const [dropdownRole, setDropdownRole] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(inputData);
  const [selectedRole, setSelectedRole] = useState(inputData);

  const [responceCheckEmailAvailability, setResponceCheckEmailAvailability] =
    useState(false);

  const [key, setKey] = useState("emailCheck");

  const roles = ["Co-ordinator", "Supervisor", "Executive", "Admin"];
  const branches = ["New York", "San Francisco", "Chicago", "Boston"];

  const [emailValid, setEmailValid] = useState(false);

  // State End------------------------------------

  // Handle functions Start------------------------------------
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeBranch = (data) => {
    setSelectedBranch(data);
  };
  const handleChangeRole = (data) => {
    setSelectedRole(data);
  };

  // Handle functions End------------------------------------
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  function validateEmail() {
    return emailRegex.test(formData.email);
  }

  useEffect(() => {
    getAllBranch();
    getAllRole();
  }, []);

  const getAllBranch = async () => {
    const responce = await getAllBranchApi();
    console.log("all branch responce----------", responce);

    const filterData = responce.map((branch) => {
      console.log(branch);

      return {
        value: branch.branch_id,
        label: branch.branch_name,
      };
    });
    console.log("filterd branchess----", filterData);
    setDropdownBranch(filterData);
  };

  const getAllRole = async () => {
    const responce = await getAllRolesApi();
    console.log("all Roles responce----------", responce);
    if (responce.success) {
      const filterData = responce.data.map((role) => {
        return {
          value: role.role_id,
          label: role.role_name,
        };
      });

      setDropdownRole(filterData);
    }
  };

  // Example usage:

  const checkEmailAvailability = async () => {
    const emailValid = validateEmail();

    if (emailValid) {
      setEmailValid(false);
      try {
        setEmailAvailablityLoader(true);
        const response = await userEmailAvailableApi(user_email);
        console.log("-----------useremail--reasponce", response);
        setEmailAvailablityLoader(false);

        if (response.availability !== "false") {
          console.log("---------------------if", response.availability);
          setResponceCheckEmailAvailability(true);
          toast.success(" Email Available");
          setKey("otherDetails");
        } else {
          console.log("---------------------else", response.availability);
          toast.error("Email Already Taken");
        }
      } catch (error) {
        // setToastMessage("Error checking email availability");

        setEmailAvailablityLoader(false);
      }
    } else {
      setEmailValid(true);
    }
  };

  const submitData = async () => {
    const userData = {
      role_id: selectedRole.value,
      user_name: formData.username,
      user_email: formData.email,
      user_branch_id: selectedBranch.value,
    };
    console.log("userdata------", userData);

    const responce = await newUserCreateApi(userData);

    console.log("user create responce----", responce);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission

    if (responceCheckEmailAvailability) {
      submitData();
    } else {
      toast.error("Enter the email");
    }
  };

  return (
    <Container className="mt-5">
      <h3 style={{ textAlign: "center" }}>Add New User</h3>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3"
      >
        <Tab eventKey="emailCheck" title="Email Check">
          <Form>
            <Form.Group controlId="formEmail" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                style={{ width: "50%", borderColor: emailValid && "red" }}
                required
              />
            </Form.Group>
            <Button
              variant="primary"
              disabled={emailAvailablityLoader}
              onClick={checkEmailAvailability}
            >
              Check Availability
            </Button>{" "}
            {emailAvailablityLoader && <Spinner animation="border" size="sm" />}
          </Form>
        </Tab>
        <Tab eventKey="otherDetails" title="Other Details">
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formUsername" className="mb-3">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                style={{ width: "50%" }}
                required
              />
            </Form.Group>

            <Form.Group controlId="formRole" className="mb-3">
              <Form.Label>Role</Form.Label>
              {/* <Form.Control
                as="select"
                name="role"
                value={formData.role}
                onChange={handleChange}
                style={{ width: "50%" }}
                required
              >
                <option value="">Select role</option>
                {roles.map((role) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </Form.Control> */}
              <Select
                name="role"
                className="w-25"
                options={dropdownRole}
                onChange={handleChangeRole}
              />
            </Form.Group>

            <Form.Group controlId="formBranch" className="mb-3">
              <Form.Label>Branch</Form.Label>
              {/* <Form.Control
                as="select"
                name="branch"
                value={formData.branch}
                onChange={handleChange}
                style={{ width: "50%" }}
                required
              >
                <option value="">Select branch</option>
                {dropdownBranch.length > 0 &&
                  dropdownBranch.map((branch) => (
                    <option key={branch} value={branch.label}>
                      {branch.label}
                    </option>
                  ))}
              </Form.Control> */}

              <Select
                className="w-25"
                options={dropdownBranch}
                onChange={handleChangeBranch}
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Tab>
      </Tabs>
    </Container>
  );
};

export default NewUser;
