import React from "react";

function UpdateUser() {
  return (
    <div style={{ backgroundColor: "red", height: 500, width: 300 }}>
      UpdateUser
    </div>
  );
}

export default UpdateUser;
