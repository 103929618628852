import { BASE_URL } from "../../../constant/Constant";

const getUserProfileApi = async (id) => {
  try {
    const responce = await fetch(
      `${BASE_URL}/user-service/users/userprofile/get/${id}`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

const getAllUsersApi = async () => {
  try {
    console.log("-------UserAll -------", localStorage.getItem("token"));
    console.log(`${BASE_URL}/user-service/users/userprofile/get_all`);
    const responce = await fetch(
      `${BASE_URL}/user-service/users/userprofile/get_all`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

export { getUserProfileApi, getAllUsersApi };
