import React, { useState } from "react";
import UserListTable from "./UserListTable";
import { Outlet } from "react-router-dom";
import "./UsersList.css";
import { Container } from "react-bootstrap";

function UsersList() {
  const [loader, setLoader] = useState(false);
  return (
    <Container>
      <div>
        <h2 className="heading">Users</h2>
        <UserListTable />
      </div>
    </Container>
  );
}

export default UsersList;
