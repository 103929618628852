import React from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Tab,
  Tabs,
  Button,
  Card,
} from "react-bootstrap";
import Select from "react-select";

const HospitalForm = () => {
  const hospitalTypes = [
    { value: "general", label: "General" },
    { value: "specialized", label: "Specialized" },
  ];

  const areas = [
    { value: "urban", label: "Urban" },
    { value: "rural", label: "Rural" },
  ];

  const states = [
    { value: "state1", label: "State 1" },
    { value: "state2", label: "State 2" },
  ];

  const districts = [
    { value: "district1", label: "District 1" },
    { value: "district2", label: "District 2" },
  ];

  const salutations = [
    { value: "mr", label: "Mr." },
    { value: "ms", label: "Ms." },
    { value: "dr", label: "Dr." },
  ];

  return (
    <Container className="mt-5">
      <Card className="shadow-sm">
        <Card.Body>
          <h2 className="mb-4 text-center">Hospital Information Form</h2>
          <Form>
            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="hospitalName">
                  <Form.Label>Hospital Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter hospital name" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="hospitalType">
                  <Form.Label>Type</Form.Label>
                  <Select options={hospitalTypes} placeholder="Select type" />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="hospitalArea">
                  <Form.Label>Area</Form.Label>
                  <Select options={areas} placeholder="Select area" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="hospitalAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control type="text" placeholder="Enter address" />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Form.Group controlId="hospitalState">
                  <Form.Label>State</Form.Label>
                  <Select options={states} placeholder="Select state" />
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group controlId="hospitalDistrict">
                  <Form.Label>District</Form.Label>
                  <Select options={districts} placeholder="Select district" />
                </Form.Group>
              </Col>
            </Row>

            <div className="text-center">
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>

      <Card className="mt-4 shadow-sm mb-5">
        <Card.Body>
          <Tabs defaultActiveKey="doctors" id="hospital-tabs" className="mb-3">
            <Tab eventKey="doctors" title="Doctors">
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="doctorSelect">
                    <Form.Label>Doctor</Form.Label>
                    <Select
                      className="w-25"
                      options={hospitalTypes}
                      placeholder="Select Doctor"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="contact" title="Contact">
              <Row className="mb-3">
                <Col md={2}>
                  <Form.Group controlId="salutation">
                    <Form.Label>Salutation</Form.Label>
                    <Select options={salutations} placeholder="Select" />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group controlId="firstName">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter first name" />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group controlId="lastName">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control type="text" placeholder="Enter last name" />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Enter email" />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group controlId="designation">
                    <Form.Label>Designation</Form.Label>
                    <Form.Control type="text" placeholder="Enter designation" />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group controlId="mobile1">
                    <Form.Label>Mobile 1</Form.Label>
                    <Form.Control type="text" placeholder="Enter mobile 1" />
                  </Form.Group>
                </Col>
                <Col md={2}>
                  <Form.Group controlId="mobile2">
                    <Form.Label>Mobile 2</Form.Label>
                    <Form.Control type="text" placeholder="Enter mobile 2" />
                  </Form.Group>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="other-details" title="Other Details">
              <Form.Group controlId="designation">
                <Form.Label>GST</Form.Label>
                <Form.Control
                  className="w-25"
                  type="text"
                  placeholder="Enter designation"
                />
              </Form.Group>
            </Tab>
            <Tab eventKey="Comments" title="Comments"></Tab>
          </Tabs>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default HospitalForm;
