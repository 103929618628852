import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  permisions: {},
};

const permisionSlice = createSlice({
  name: "permisions",
  initialState,
  reducers: {
    setPermisions: (state, action) => {
      state.permisions = action.payload;
    },
  },
});

export const { setPermisions } = permisionSlice.actions;

export default permisionSlice.reducer;
