import { BASE_URL } from "../../../constant/Constant";

const getAllRolesApi = async () => {
  try {
    console.log("--------------", localStorage.getItem("token"));
    const responce = await fetch(
      `${BASE_URL}/user-service/users/userrole/get_all`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

const getPermissionsApi = async (role_id) => {
  try {
    console.log("--------------", localStorage.getItem("token"));
    const responce = await fetch(
      `${BASE_URL}/user-service/users/default_permissions/get/${role_id}`,
      {
        method: "GET",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });
    return responce;
  } catch (error) {
    console.log(error);
  }
};

export { getAllRolesApi, getPermissionsApi };
