import { configureStore } from "@reduxjs/toolkit";

import authSliceReducer from "../slices/slice";
import permisionSliceReducer from "../slices/permisionSlice";
import viewOrderSliceReducer from "../slices/orderSlice";

const store = configureStore({
  reducer: {
    auth: authSliceReducer,
    permisions: permisionSliceReducer,
    viewOrders: viewOrderSliceReducer,
  },
});

export default store;
