import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Card,
  CardBody,
  CardTitle,
  CardText,
} from "reactstrap";
import {
  FaUser,
  FaHospital,
  FaFileInvoiceDollar,
  FaStickyNote,
} from "react-icons/fa";

import { Container } from "react-bootstrap";
import DotLoader from "../loader/DotLoader";
import { getHospitalDetailApi } from "./hospitalApi/hospitalApi";

function ViewHospital() {
  const location = useLocation();
  const { state } = location;

  const [loader, setLoader] = useState(false);

  const [hospitalId, setHospitalId] = useState(state.data);
  const [hospitalDetails, setHospitalDetails] = useState({});

  const [notesValue, setNotesValue] = useState("");
  const [notesValueCopy, setNotesValueCopy] = useState("");

  const getHospitalDetail = async (id) => {
    setLoader(true);
    const response = await getHospitalDetailApi(hospitalId);
    setLoader(false);
    if (response.success) {
      setHospitalDetails(response.data);
      setNotesValue(response.data.customer_comments);
      setNotesValueCopy(response.data.customer_comments);
    }
  };

  useEffect(() => {
    getHospitalDetail();
  }, []);

  const notesOnChange = (e) => {
    setNotesValue(e.target.value);
  };

  const updateCommentNotes = async () => {
    const commentData = {
      customer_comments: notesValue,
      modified_by: localStorage.getItem("userid"),
    };
    // const response = await customerCommentsUpdate(commentData, customerId);
  };

  const handleCancelNotes = () => {
    setNotesValue(notesValueCopy);
  };
  const handleSaveNotes = () => {
    setNotesValueCopy(notesValue);
    updateCommentNotes();
  };

  return (
    <Container>
      {loader ? (
        <DotLoader></DotLoader>
      ) : (
        <div>
          <h3 className="mb-4">
            {hospitalDetails.hospital_name}{" "}
            {/* {customerDetails.customer_last_name} */}
          </h3>

          <Tabs
            defaultActiveKey="overview"
            id="uncontrolled-tab-example"
            className="mb-3 mt-5"
          >
            <Tab
              eventKey="overview"
              title={
                <>
                  <FaUser /> Overview
                </>
              }
            >
              <Card className="w-50">
                <CardBody>
                  <CardTitle tag="h5">Hospital Details</CardTitle>
                  <CardText>
                    <table className="table" style={{ borderSpacing: 10 }}>
                      <tbody>
                        <tr>
                          <td>Phone:</td>
                          <td className="fw-bold">
                            {/* {hospitalDetails.customer_phone_number} */}
                          </td>
                        </tr>
                        <tr>
                          <td>Type:</td>
                          <td className="fw-bold">
                            {/* {customerDetails.customer_type_name} */}
                          </td>
                        </tr>
                        <tr>
                          <td>Payment Type:</td>
                          <td className="fw-bold">
                            {/* {customerDetails.customer_payment_type_name} */}
                          </td>
                        </tr>
                        <tr>
                          <td>Specialization:</td>
                          <td className="fw-bold">
                            {/* {customerDetails.customer_specialization} */}
                          </td>
                        </tr>
                        <tr>
                          <td>Branch:</td>
                          <td className="fw-bold">
                            {/* {customerDetails.branch_name} */}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </CardText>
                </CardBody>
              </Card>
            </Tab>

            <Tab
              eventKey="hospital"
              title={
                <>
                  <FaHospital /> Doctors
                </>
              }
            >
              <Card className="w-75">
                <CardBody>
                  <CardTitle tag="h5">Associated Hospitals</CardTitle>
                  {/* {Object.keys(customerDetails).length > 0 &&
                  customerDetails.hospital_junction.length > 0 ? (
                    <ListGroup>
                      {customerDetails.hospital_junction.map((hospital) => (
                        <ListGroupItem
                          key={hospital.hospital_id}
                          className="w-50"
                        >
                          <ListGroupItemHeading>
                            {hospital.hospital_name}
                          </ListGroupItemHeading>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  ) : (
                    <p>No Hospitals Associated</p>
                  )} */}
                </CardBody>
              </Card>
            </Tab>

            <Tab
              eventKey="transaction"
              title={
                <>
                  <FaFileInvoiceDollar /> Transaction
                </>
              }
            >
              <Card className="w-75">
                <CardBody>
                  <CardTitle tag="h5">Transactions</CardTitle>
                  <CardText>
                    Transaction details will be displayed here.
                  </CardText>
                </CardBody>
              </Card>
            </Tab>

            <Tab
              eventKey="notes"
              title={
                <>
                  <FaStickyNote /> Notes
                </>
              }
            >
              <Card className="w-75">
                <CardBody>
                  <CardTitle tag="h5">Customer Notes</CardTitle>
                  <textarea
                    value={notesValue}
                    onChange={notesOnChange}
                    className="form-control"
                    rows="5"
                  ></textarea>
                  <div className="mt-3">
                    <Button onClick={handleSaveNotes} size="sm" color="primary">
                      Save
                    </Button>
                    <Button
                      onClick={handleCancelNotes}
                      className="mx-2"
                      size="sm"
                    >
                      Cancel
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </Tab>
          </Tabs>
        </div>
      )}
    </Container>
  );
}

export default ViewHospital;
