import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./StockCategory.css";
import { useNavigate } from "react-router-dom";

const category = [
  {
    id: 1,
    name: "SS Implants",
  },
  {
    id: 2,
    name: "TFT Implants",
  },
  {
    id: 3,
    name: "SS Surgicals",
  },
  {
    id: 4,
    name: "TFT Surgicals",
  },
];

function StockCategory() {
  const navigate = useNavigate();

  const handleCategory = (item) => {
    navigate(`/stock/category-details/${item.id}`);
  };
  return (
    <Container>
      <h3>Category</h3>
      <Row>
        {category.map((item) => (
          <Col
            xs={6}
            key={item.id}
            onClick={() => {
              handleCategory(item);
            }}
            className="category-column mt-4"
          >
            {item.name}
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default StockCategory;
