import React, { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { Colors, SIZE } from "../../constant/Constant";
import { MdAssignmentAdd, MdDelete, MdOutlineAddCircle } from "react-icons/md";
import { getAllRolesApi } from "./rolesapi/rolesApi";
import { CiEdit } from "react-icons/ci";
import { FaRegCopy } from "react-icons/fa";
import "./RoleListTable.css"; // Import the CSS file
import CustomModel from "../common/CustomModel";
import AssignRole from "./AssignRole";

const RoleListTable = () => {
  const navigate = useNavigate();
  const [allRoles, setAllRoles] = useState([]);

  const handleRowClick = (id) => {
    navigate(`/setting/updateuser/${id}`);
  };

  useEffect(() => {
    initialFunction();
  }, []);

  const initialFunction = () => {
    getAllUsers();
  };

  const getAllUsers = async () => {
    const response = await getAllRolesApi();
    if (response.success) {
      setAllRoles(response.data);
    }
  };

  const handleEdit = (id) => {
    navigate(`/setting/editrole/${id}`);
  };

  const handleClone = (id) => {
    navigate(`/setting/newrole`, { state: { role_id: id } });
    console.log("Cloning role with id:", id);
  };

  const handleDelete = (id) => {
    // Implement delete functionality here
    console.log("Deleting role with id:", id);
  };

  return (
    <Container>
      <div className="d-flex justify-content-end mb-5 mt-2">
        <div className="icon-container">
          <MdAssignmentAdd
            className="mx-2"
            style={{
              color: Colors.OrthoColor,
              height: SIZE.Height,
              width: SIZE.Width,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/setting/assign-role");
            }}
          />
          <span className="icon-text">Assign Role</span>
        </div>
        {/* <CustomModel title={"Assign Role"} body={<AssignRole/>}/> */}
        <div className="icon-container">
          <MdOutlineAddCircle
            className="mx-2"
            style={{
              color: Colors.OrthoColor,
              height: SIZE.Height,
              width: SIZE.Width,
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/setting/newrole");
            }}
          />
          <span className="icon-text">Create New</span>
        </div>

        {/* <MdOutlineAddCircle
          style={{
            color: Colors.OrthoColor,
            height: SIZE.Height,
            width: SIZE.Width,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/setting/newrole");
          }}
        /> */}
      </div>
      <Table striped bordered hover className="shadow-sm ">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Role Name</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {allRoles.length > 0 &&
            allRoles.map((item, index) => (
              <tr key={index} style={{ cursor: "pointer" }}>
                <td>{index + 1}</td>
                <td>{item.role_name}</td>
                <td>{item.description}</td>
                <td>
                  <div className="icon-container">
                    <CiEdit
                      color="grey"
                      className="m-2"
                      onClick={() => handleEdit(item.role_id)}
                    />
                    <span className="icon-text">Edit</span>
                  </div>
                  <div className="icon-container">
                    <FaRegCopy
                      color="grey"
                      className="m-2"
                      onClick={() => handleClone(item.role_id)}
                    />
                    <span className="icon-text">Clone</span>
                  </div>
                  <div className="icon-container">
                    <MdDelete
                      color="grey"
                      className="m-2"
                      onClick={() => handleDelete(item.role_id)}
                    />
                    <span className="icon-text">Delete</span>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default RoleListTable;
