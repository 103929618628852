import { BASE_URL } from "../../../constant/Constant";

const newCustomerCreateApi = async (customerData) => {
  console.log("send data customr---", customerData);

  try {
    const responce = await fetch(
      `${BASE_URL}/customer-service/customer/create`,
      {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(customerData),
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });

    return responce;
  } catch (error) {
    console.log(error);
  }
};

export { newCustomerCreateApi };
