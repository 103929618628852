import React from "react";
import "./RoleHome.css";
import RoleListTable from "./RoleListTable";
import { Outlet } from "react-router-dom";

function RoleHome() {
  return (
    <div>
      <h2 className="heading">Roles</h2>
      <RoleListTable />
      <Outlet />
    </div>
  );
}

export default RoleHome;
