import React from "react";
import "./DotLoader.css";

const DotLoader = (props) => {
  return (
    <div className="loader-container">
      <div className="dots-7"></div>
    </div>
  );
};

export default DotLoader;
