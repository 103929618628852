import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  viewOrder: false,
};

const viewOrderSlice = createSlice({
  name: "viewOrder",
  initialState,
  reducers: {
    setViewOrder: (state, action) => {
      state.viewOrder = action.payload;
    },
  },
});

export const { setViewOrder } = viewOrderSlice.actions;

export default viewOrderSlice.reducer;
