import React, { useEffect, useState } from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { Colors } from "../../constant/Constant";
import { userSignInApi } from "../../api/users/SignInApi";
import { useDispatch } from "react-redux";
import { setLoginStatus } from "../../redux/slices/slice";

const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [userData, setUserData] = useState({});

  const loginData = {
    // user_name: "Admin",
    // user_password: "Admin@123",
    user_email: "gowri848920@gmail.com",
    user_password: "Gowri@123",
    // user_email: "pmsubash14@gmail.com",
    // user_password: "Subash@123",

    // user_email: "jithendranjithu003@gmail.com",
    // user_password: "Jithu@123",
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform sign-in logic here

    console.log("Email:", email);
    console.log("Password:", password);
    signIn();
  };

  useEffect(() => {}, []);

  const signIn = async () => {
    try {
      const responce = await userSignInApi(loginData);

      // console.log(responce);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Container className="mt-5">
      <Row className="justify-content-md-center">
        <Col md="4">
          <h2>Sign In</h2>
          <Form onSubmit={handleSubmit} className="mt-5">
            <Form.Group controlId="formBasicEmail" className="mb-3">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // required
              />
            </Form.Group>

            <Form.Group controlId="formBasicPassword" className="mb-3">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                // required
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              Sign In
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default SignIn;
