import React from "react";
import InputGroupText from "react-bootstrap/esm/InputGroupText";
import "./Setting.css";
import { FaSearch } from "react-icons/fa";
import { CiSearch } from "react-icons/ci";
import { Card, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

function SettingMenu() {
  const navigate = useNavigate();
  const { permisions } = useSelector((state) => state);
  const permission = JSON.parse(permisions.permisions);
  return (
    <div className="main-container">
      <div className="d-flex  align-items-center">
        <h2 className="heading">Settings</h2>
        <input className="search-input" placeholder="Search" />

        {/* <CiSearch className="search-icon" /> */}
      </div>
      {permission.Users.ModuleVisibility.view_allow == "true" && (
        <div style={{ marginTop: 50 }}>
          <Card style={{ width: "18rem" }} className="card">
            <Card.Header className="card-header">
              <h5 style={{ textAlign: "center" }}>Users</h5>
            </Card.Header>
            <ListGroup>
              <ListGroup.Item
                className="card-item"
                onClick={() => {
                  navigate("userlist");
                }}
              >
                User List
              </ListGroup.Item>
              <ListGroup.Item
                onClick={() => {
                  navigate("roles");
                }}
                className="card-item"
              >
                Roles
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </div>
      )}
    </div>
  );
}

export default SettingMenu;
