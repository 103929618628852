import React, { useState, useEffect } from "react";
import { Container, Table, Form, Button, Spinner } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import "./NewRole.css";
import { getPermissionsApi } from "./rolesapi/rolesApi";

const PermissionManager = () => {
  const [permissions, setPermissions] = useState({});
  const [selectedRole, setSelectedRole] = useState("Select Role");
  const [roleName, setRoleName] = useState("");
  const [selectAllStatus, setSelectAllStatus] = useState({});
  const [roleId, setRoleId] = useState();
  const { state } = useLocation();
  const [loader, setLoader] = useState(false);

  // const { data } = location.state || {};

  const handleCheckboxChange = (category, permissionType, field) => {
    setPermissions((prevPermissions) => {
      const updatedPermissions = {
        ...prevPermissions,
        [category]: {
          ...prevPermissions[category],
          [permissionType]: {
            ...prevPermissions[category][permissionType],
            [field]:
              prevPermissions[category][permissionType][field] === "true"
                ? "false"
                : "true",
          },
        },
      };

      const allChecked = Object.values(
        updatedPermissions[category][permissionType]
      ).every((value) => value === "true");

      setSelectAllStatus((prevStatus) => ({
        ...prevStatus,
        [`${category}-${permissionType}`]: allChecked,
      }));

      return updatedPermissions;
    });
  };
  useEffect(() => {
    console.log(selectAllStatus);
    console.log(permissions);
  }, [selectAllStatus]);

  const handleSelectAllChange = (category, permissionType) => {
    const newStatus = !selectAllStatus[`${category}-${permissionType}`];
    setSelectAllStatus((prevStatus) => ({
      ...prevStatus,
      [`${category}-${permissionType}`]: newStatus,
    }));

    setPermissions((prevPermissions) => ({
      ...prevPermissions,
      [category]: {
        ...prevPermissions[category],
        [permissionType]: {
          view_allow: newStatus ? "true" : "false",
          edit_allow: newStatus ? "true" : "false",
          create_allow: newStatus ? "true" : "false",
          delete_allow: newStatus ? "true" : "false",
        },
      },
    }));
  };

  useEffect(() => {
    console.log("---------------", state);
    const permision = JSON.parse(localStorage.getItem("permisions"));
    console.log(permision);

    if (state) {
      setRoleId(state.role_id || 0);
      getPermissions(state.role_id);
    } else {
      setRoleId(7);
      getPermissions(7);
    }
  }, []);

  const getPermissions = async (roleId) => {
    setLoader(true);
    const responce = await getPermissionsApi(roleId);
    setLoader(false);
    setPermissions(responce.modules || {});
    console.log("ressssssssssssssssssss", responce);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("Role Name:", roleName);
    console.log("Selected Role:", selectedRole);
    console.log("Updated Permissions:", permissions);
  };

  const handleRoleSelect = (role) => {
    setSelectedRole(role);
  };

  return (
    <Container>
      <h2 className="heading">New Role</h2>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-4" controlId="roleName">
          <Form.Label>Enter Role Name</Form.Label>
          <Form.Control
            style={{ width: 200 }}
            type="text"
            placeholder="Role Name"
            value={roleName}
            onChange={(e) => setRoleName(e.target.value)}
          />
        </Form.Group>
        {loader ? (
          <div className="spinnerContainer">
            <Spinner animation="border" size="lg" />
          </div>
        ) : (
          <div className="tableContainer">
            <Table striped bordered hover>
              <thead className="tableHead">
                <tr>
                  <th>Category</th>
                  <th>Permission Type</th>
                  <th>View</th>
                  <th>Edit</th>
                  <th>Create</th>
                  <th>Delete</th>
                  <th>Select All</th>
                </tr>
              </thead>
              {loader ? (
                <Spinner animation="border" size="sm" />
              ) : (
                <tbody>
                  {Object.keys(permissions).map((category) => {
                    const categoryPermissions = permissions[category];
                    const permissionTypes = Object.keys(categoryPermissions);
                    return (
                      <React.Fragment key={category}>
                        <tr>
                          <td
                            colSpan="7"
                            style={{
                              fontWeight: "bold",
                              backgroundColor: "#f8f9fa",
                            }}
                          >
                            {category}
                          </td>
                        </tr>
                        {permissionTypes.map((permissionType) => (
                          <React.Fragment key={`${category}-${permissionType}`}>
                            <tr>
                              <td>
                                {/* Empty cell to skip category column */}
                              </td>
                              <td>{permissionType}</td>
                              {[
                                "view_allow",
                                "edit_allow",
                                "create_allow",
                                "delete_allow",
                              ].map((field) => (
                                <td
                                  key={`${category}-${permissionType}-${field}`}
                                >
                                  <Form.Check
                                    type="checkbox"
                                    id={`${category}-${permissionType}-${field}`}
                                    checked={
                                      categoryPermissions[permissionType][
                                        field
                                      ] === "true"
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        category,
                                        permissionType,
                                        field
                                      )
                                    }
                                  />
                                </td>
                              ))}
                              <td>
                                <Form.Check
                                  type="checkbox"
                                  id={`select-all-${category}-${permissionType}`}
                                  checked={
                                    selectAllStatus[
                                      `${category}-${permissionType}`
                                    ] || false
                                  }
                                  onChange={() =>
                                    handleSelectAllChange(
                                      category,
                                      permissionType
                                    )
                                  }
                                />
                              </td>
                            </tr>
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              )}
            </Table>
          </div>
        )}
        <Button
          className="mt-2"
          variant="primary"
          type="submit"
          onClick={() => {
            // createPermisionSend();
          }}
        >
          Save Permissions
        </Button>
      </Form>
    </Container>
  );
};

export default PermissionManager;
