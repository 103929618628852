// src/components/Navbar.js
import React, { useEffect, useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { FaBeer, FaHome } from "react-icons/fa";
import { IoSettingsSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import "./Navbar.css";
const FixedNavbar = () => {
  const navigation = useNavigate();

  const [userName, setUserName] = useState("");

  useEffect(() => {
    getUserName();
  }, []);

  const getUserName = () => {
    const username = localStorage.getItem("username");
    setUserName(username);
  };

  return (
    <Container className="navbar-div">
      <Navbar className="navbar-div" expand="lg" fixed="top">
        <Navbar.Brand
          onClick={() => {
            navigation("/");
          }}
        >
          Ortholink
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav
            className="ms-auto p "
            style={{
              paddingRight: 10,
              paddingLeft: 10,
            }}
          >
            {" "}
            <div
              className="mr-3 d-flex justify-content-center align-items-center"
              style={{ paddingRight: 10 }}
            >
              {" "}
              <FaHome
                style={{
                  color: "black",
                  height: 20,
                  width: 20,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigation("/");
                }}
                className="mx-2"
              />
            </div>
            <div
              className="mr-3 d-flex justify-content-center align-items-center"
              style={{ paddingRight: 10 }}
            >
              {" "}
              {/* "mr-3" adds margin-right of 0.75rem (12px) */}
              <IoSettingsSharp
                style={{
                  color: "black",
                  height: 20,
                  width: 20,
                  cursor: "pointer",
                }}
                onClick={() => {
                  navigation("setting");
                }}
              />
            </div>
            <div
              style={{
                backgroundColor: "wheat",
                height: 40,
                width: 40,
                borderRadius: 50,
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                fontWeight: "bold",
              }}
            >
              {userName && userName.charAt(0).toUpperCase()}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </Container>
  );
};

export default FixedNavbar;
