// src/components/MainContent.js
import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../home/Home";

import PageNotFound from "../common/PageNotFound";
import Setting from "../setting/Setting";
import UsersList from "../users/UsersList";
import UpdateUser from "../users/UpdateUser";
import NewUser from "../users/NewUser";
import SettingMenu from "../setting/SettingMenu";

import Customer from "../customer/Customer";
import NewCustomerForm from "../customer/NewCustomer";
import UpdateCustomer from "../customer/CustomerUpdate";
import RoleHome from "../roles/RoleHome";
import NewRole from "../roles/NewRole";
import CloneEditRole from "../roles/CloneEditRole";
import AssignRole from "../roles/AssignRole";
import Hospital from "../hospital/Hospital";
import NewHospital from "../hospital/NewHospital";
import ViewCustomer from "../customer/ViewCustomer";
import ViewHospital from "../hospital/ViewHospital";
// import Stock from "../stock/Stock";
import StockPageMain from "../stock/StockPageMain";
import StockCategory from "../stock/StockCategory";
import StockCategoryDetails from "../stock/StockCategoryDetails";
import OrderHome from "../order/OrderHome";
import NewOrder from "../order/NewOrder";
import ViewOrder from "../order/ViewOrder";
import OrderInfo from "../order/OrderInfo";
import OrderTrack from "../order/OrderTrack";
import OrderStatus from "../order/OrderStatus";

const MainContent = () => {
  return (
    <div
      className="ml-auto mt-5"
      style={{
        marginLeft: "250px",
        marginTop: "56px",
        padding: "20px",
        transition: "margin-left 0.3s",
      }}
    >
      <Routes>
        <Route path="/" element={<Home />}></Route>

        <Route path="setting/*" element={<Setting />}>
          <Route index element={<SettingMenu />} />
          <Route path="settingmenu/*" element={<SettingMenu />} />
          <Route path="userlist/*" element={<UsersList />} />
          <Route path="updateuser/:userId" element={<UpdateUser />} />
          <Route path="newuser" element={<NewUser />} />
          <Route path="roles/*" element={<RoleHome />}></Route>
          <Route path="newrole" element={<NewRole />} />
          <Route path="assign-role" element={<AssignRole />} />
        </Route>

        <Route path="customer/*" element={<Customer />}></Route>
        <Route path="customer/newcustomer" element={<NewCustomerForm />} />
        <Route path="customer/view/:customerId" element={<ViewCustomer />} />

        <Route path="hospital/*" element={<Hospital />}></Route>
        <Route path="hospital/newhospital" element={<NewHospital />} />
        <Route path="hospital/view/:hospitalId" element={<ViewHospital />} />

        <Route path="/stock">
          <Route index element={<StockCategory />} />
          <Route path="category" element={<StockCategory />} />
          <Route
            path="category-details/:id"
            element={<StockCategoryDetails />}
          />
        </Route>
        <Route path="/orders">
          <Route index element={<OrderHome />} />
          <Route path="home" element={<OrderHome />} />
          <Route path="view-order" element={<ViewOrder />}>
            <Route path="order-info" element={<OrderInfo />} />
            <Route path="order-track" element={<OrderTrack />} />
            <Route path="order-status" element={<OrderStatus />} />
          </Route>

          <Route path="new-order" element={<NewOrder />} />
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default MainContent;
