import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loginStatus: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoginStatus: (state, action) => {
      console.log("set Login status", action);

      state.loginStatus = action.payload;
    },
  },
});

console.log(authSlice);

export const { setLoginStatus } = authSlice.actions;

export default authSlice.reducer;
