import React, { useEffect } from "react";
import { Container, Nav } from "react-bootstrap";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  FaClipboardList,
  FaTruck,
  FaExchangeAlt,
  FaRedo,
} from "react-icons/fa";
import "./ViewOrder.css";
import OrderInfo from "./OrderInfo";
import { useDispatch, useSelector } from "react-redux";
import { setViewOrder } from "../../redux/slices/orderSlice";

function ViewOrder() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const navigate = useNavigate();

  // dispatch(setPermisions(permisions));

  const location = useLocation();

  useEffect(() => {
    // Component mount logic
    console.log("Component mounted");
    console.log(state);
    dispatch(setViewOrder(true));
    return () => {
      dispatch(setViewOrder(false));
      // Component unmount logic
      console.log("Component unmounted");
    };
  }, [location]);

  return (
    <Container style={{ backgroundColor: "green" }}>
      <h3>View Order</h3>
      <Outlet />
    </Container>
  );
}

export default ViewOrder;
