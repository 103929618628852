import React, { useState } from "react";
import { Container, Table } from "react-bootstrap";
import Select from "react-select";
import data from "./testfinal.json";
import "../../styles/ProductGroupView.css";

function ProductGroupView() {
  const [allGroupData] = useState(data);
  const [selectedBranch, setSelectedBranch] = useState(null);

  // Extract branch options for the dropdown
  const branchOptions = allGroupData.map((branch) => ({
    value: branch.branch_id,
    label:
      branch.branch_name.charAt(0).toUpperCase() + branch.branch_name.slice(1),
  }));

  // Filter data based on selected branch
  const filteredGroupData = selectedBranch
    ? allGroupData.filter((branch) => branch.branch_id === selectedBranch.value)
    : allGroupData;

  // Calculate quantities for each branch
  const calculateTotal = (
    productGroupSet,
    productGroupAdditional,
    productName
  ) => {
    let quantities = productGroupSet.map((set) => {
      const product = set.products.find((p) => p.product_name === productName);
      return product ? product.quantity : 0;
    });

    let additionalQty = 0;
    if (productGroupAdditional.length > 0) {
      const additional = productGroupAdditional[0].b.find(
        (p) => p.product_name === productName
      );
      additionalQty = additional ? additional.quantity : 0;
    }

    const total = quantities.reduce((acc, qty) => acc + qty, 0) + additionalQty;

    return {
      quantities,
      additionalQty,
      total,
    };
  };

  // Extract unique product names
  const productNames = Array.from(
    new Set(
      allGroupData.flatMap((branch) =>
        branch.product_group_set.flatMap((set) =>
          set.products.map((p) => p.product_name)
        )
      )
    )
  );

  return (
    <Container>
      <h3 className="mt-5 mb-3 text-center">
        Locking Medial Clavicle Plate 3.5mm
      </h3>

      {/* Branch Filter */}
      <div className="mb-3 d-flex justify-content-end">
        <Select
          options={branchOptions}
          onChange={(selectedOption) => setSelectedBranch(selectedOption)}
          isClearable
          placeholder="Filter by Branch"
          className="w-25"
        />
      </div>

      <Table bordered hover responsive className="custom-table">
        <thead>
          <tr>
            <th rowSpan="2" className="align-middle text-center">
              S.no
            </th>
            <th rowSpan="2" className="align-middle text-center">
              Products
            </th>
            {filteredGroupData.map((branch, index) => (
              <th
                key={index}
                className="text-center"
                colSpan={
                  branch.product_group_set.length +
                  (branch.additional.length > 0 ? 1 : 0)
                }
              >
                {branch.branch_name.charAt(0).toUpperCase() +
                  branch.branch_name.slice(1)}
              </th>
            ))}
            <th rowSpan="2" className="align-middle text-center">
              Total
            </th>
          </tr>
          <tr>
            {filteredGroupData.map((branch, branchIndex) => (
              <React.Fragment key={branchIndex}>
                {branch.product_group_set.map((set, setIndex) => (
                  <th key={setIndex}>{set.product_group_set_name}</th>
                ))}
                {branch.additional.length > 0 && <th>Additional</th>}
              </React.Fragment>
            ))}
          </tr>
        </thead>
        <tbody>
          {productNames.map((productName, productIndex) => {
            let totalQty = 0;
            return (
              <tr key={productIndex}>
                <td className="text-center">{productIndex + 1}</td>
                <td>{productName}</td>
                {filteredGroupData.map((branch, branchIndex) => {
                  const { quantities, additionalQty, total } = calculateTotal(
                    branch.product_group_set,
                    branch.additional,
                    productName
                  );
                  totalQty += total;
                  return (
                    <React.Fragment key={branchIndex}>
                      {quantities.map((qty, qtyIndex) => (
                        <td key={qtyIndex} className="text-center">
                          {qty}
                        </td>
                      ))}
                      {branch.additional.length > 0 && (
                        <td className="text-center">{additionalQty}</td>
                      )}
                    </React.Fragment>
                  );
                })}
                <td className="text-center">{totalQty}</td>
              </tr>
            );
          })}
          <tr>
            <td colSpan="2" className="text-center font-weight-bold">
              Total
            </td>
            {filteredGroupData.map((branch, branchIndex) => {
              let branchTotal = 0;
              productNames.forEach((productName) => {
                const { total } = calculateTotal(
                  branch.product_group_set,
                  branch.additional,
                  productName
                );
                branchTotal += total;
              });
              return (
                <React.Fragment key={branchIndex}>
                  <td
                    colSpan={
                      branch.product_group_set.length +
                      (branch.additional.length > 0 ? 1 : 0)
                    }
                    className="text-center font-weight-bold"
                  >
                    {branchTotal}
                  </td>
                </React.Fragment>
              );
            })}
            <td className="text-center font-weight-bold">
              {filteredGroupData.reduce((overallTotal, branch) => {
                return (
                  overallTotal +
                  productNames.reduce((sum, productName) => {
                    const { total } = calculateTotal(
                      branch.product_group_set,
                      branch.additional,
                      productName
                    );
                    return sum + total;
                  }, 0)
                );
              }, 0)}
            </td>
          </tr>
        </tbody>
      </Table>
    </Container>
  );
}

export default ProductGroupView;
