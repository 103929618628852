import { BASE_URL } from "../../../constant/Constant";

const userEmailAvailableApi = async (user_email) => {
  console.log(localStorage.getItem("token"), user_email);
  try {
    const responce = await fetch(
      `${BASE_URL}/user-service/users/userprofile/email_validate`,
      {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user_email),
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });

    return responce;
  } catch (error) {
    console.log(error);
  }
};

const newUserCreateApi = async (userData) => {
  console.log(localStorage.getItem("token"), userData);
  try {
    const responce = await fetch(
      `${BASE_URL}/user-service/users/userprofile/create`,
      {
        method: "POST",
        headers: {
          Authorization: localStorage.getItem("token"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      }
    )
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        console.log("Api Error", err);
      });

    return responce;
  } catch (error) {
    console.log(error);
  }
};

export { userEmailAvailableApi, newUserCreateApi };
