import React, { useEffect, useReducer, useState } from "react";
import {
  Button,
  Container,
  Table,
  Pagination,
  Form,
  Spinner,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Outlet, useNavigate } from "react-router-dom";
import { Colors } from "../../constant/Constant";
import { MdOutlineAddCircle } from "react-icons/md";
import { getAllCustomersApi } from "./customersApi/customerProfileApi";
import Select from "react-select";
import { DataGrid } from "@mui/x-data-grid";
import "../../styles/tableDataGrid.css";
import DotLoader from "../loader/DotLoader";
import { useSelector } from "react-redux";

const columns = [
  {
    field: "customer_first_name",
    headerName: "DOCTOR",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
    // hide: false,
  },
  {
    field: "role_name",
    headerName: "HOSPITAL",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
  {
    field: "customer_phone_number",
    headerName: "CONTACT NO",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
  {
    field: "user_email",
    headerName: "CITY",

    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
  {
    field: "customer_area_name",
    headerName: "AREA",
    type: "email",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
];
const CustomerListTable = () => {
  const loginStatus = useSelector((state) => state.auth.loginStatus);
  const data = useReducer();
  console.log("------------------- redux login status", loginStatus);

  const navigate = useNavigate();
  const [allCustomers, setAllCustomers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [customersPerPage, setCustomersPerPage] = useState(10);
  const [loader, setLoader] = useState(false);

  const handleCustomer = (id) => {
    navigate(`/customer/view/${id}`, { state: { data: id } });
  };

  const handleHospital = (id) => {
    navigate(`/hospital/view/${id}`, { state: { data: id } });
  };

  useEffect(() => {
    initialFunction();
  }, []);

  const initialFunction = () => {
    getAllCustomers();
  };

  const getAllCustomers = async () => {
    setLoader(true);
    const response = await getAllCustomersApi();
    console.log("All customers-----", response);
    setLoader(false);
    if (response.success) {
      const filterData = response.data.map((item) => {
        return {
          ...item,
          id: item.customer_id,
        };
      });
      setAllCustomers(filterData);
    } else {
      console.error("Invalid response format: expected an array");
    }
    console.log("cus....", response);
  };

  const indexOfLastCustomer = currentPage * customersPerPage;
  const indexOfFirstCustomer = indexOfLastCustomer - customersPerPage;
  const currentCustomers = allCustomers.slice(
    indexOfFirstCustomer,
    indexOfLastCustomer
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(allCustomers.length / customersPerPage);

  const handleCustomersPerPageChange = (e) => {
    setCustomersPerPage(Number(e.target.value));
    setCurrentPage(1); // Reset to the first page
  };

  return (
    <Container>
      {loader ? (
        <DotLoader />
      ) : (
        <div>
          <div className="d-flex justify-content-end mb-5 mt-2">
            <div className="w-40 mx-4">
              <Select placeholder="Filter Branch" className="w-100 " />
            </div>

            <MdOutlineAddCircle
              style={{
                color: Colors.OrthoColor,
                height: 40,
                width: 40,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/customer/newcustomer");
              }}
            />
          </div>

          <h3 className="mb-4">Customers</h3>

          <div className="container-div">
            <DataGrid
              onCellClick={(cell) => {
                console.log(cell.field);
                if (cell.field === "customer_first_name") {
                  console.log("cell ", cell);
                  handleCustomer(cell.id);
                  // Custom logic for clicking the "Name" column
                }
              }}
              loading={loader}
              rows={allCustomers}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 20, 50]}
              sx={{
                boxShadow: 2,

                "& .MuiDataGrid-columnHeaders": {
                  position: "sticky",
                  top: 0,
                  zIndex: 1100,
                  color: Colors.BlackLight,
                  boxShadow: "3px 3px 10px -3px #dddddd",
                  "& .MuiDataGrid-menuIcon": {
                    visibility: "visible",
                    width: "auto",
                  },
                  "& .MuiDataGrid-sortIcon": {
                    opacity: 1,
                    visibility: "visible",
                  },

                  fontFamily: "'Roboto', sans-serif",
                },
                "& .MuiDataGrid-cell": {
                  overflow: "hidden",
                },

                "& .MuiDataGrid-cell[data-field='customer_first_name']": {
                  color: Colors.LinkColor,
                  cursor: "pointer",
                },
                "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                  outline: "none",
                  border: "none",
                },
              }}
            />
          </div>
          {/* {loader ? (
        <div className="d-flex justify-content-center">
          <Spinner animation="border" size="lg" />
        </div>
      ) : (
        <div>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Doctor </th>
                <th>Hospital</th>
                <th>Contact No</th>
                <th>City</th>
                <th>Area</th>
              </tr>
            </thead>
            <tbody>
              {currentCustomers.length > 0 &&
                currentCustomers.map((item, index) => (
                  <tr
                    key={index}
                    // style={{ cursor: "pointer" }}
                  >
                    <td>
                      {" "}
                      <p
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleCustomer(item.customer_id);
                        }}
                      >
                        {" "}
                        {item.customer_first_name}
                      </p>
                    </td>
                    <td>
                      <p
                        className="text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleHospital(item.customer_id);
                        }}
                      >
                        {" "}
                        {item.customer_first_name}
                      </p>
                    </td>
                    <td>{item.customer_phone_number}</td>
                    <td>{}</td>
                    <td>{}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>
      )} */}
        </div>
      )}

      <Outlet></Outlet>
    </Container>
  );
};

export default CustomerListTable;
