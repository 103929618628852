import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import { Colors } from "../../constant/Constant";
import UpdateUser from "./UpdateUser";
import { MdOutlineAddCircle } from "react-icons/md";
import { getAllUsersApi } from "../../api/users/usersApi/userProfileApi";
import { DataGrid } from "@mui/x-data-grid";
import "./UserListTable.css";
import { useDispatch, useSelector } from "react-redux";

const columns = [
  {
    field: "user_name",
    headerName: "Name",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
    // hide: false,
  },
  {
    field: "role_name",
    headerName: "Role",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
  {
    field: "branch_name",
    headerName: "Branch",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
  {
    field: "user_email",
    headerName: "Email",
    type: "email",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
];

const UserListTable = () => {
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { permisions } = useSelector((state) => state);
  const permission = JSON.parse(permisions.permisions);

  const handleRowClick = (id) => {
    navigate(`/setting/updateuser/${id}`);
  };

  useEffect(() => {
    initialFunction();
    console.log("State permissions----", permission);
  }, []);

  const initialFunction = () => {
    getAllUsers();
  };

  const getAllUsers = async () => {
    setLoading(true);
    const responce = await getAllUsersApi();
    console.log("rrrrrrrrrrrr", responce);
    const filterData = responce.data.map((item) => {
      return {
        ...item,
        id: item.user_id,
      };
    });
    console.log("-------------------", filterData);

    setAllUsers(filterData);
    setLoading(false);
  };

  return (
    <Container>
      <div className="d-flex justify-content-end mb-5 mt-2">
        <MdOutlineAddCircle
          style={{
            color: Colors.LinkColor,
            height: 40,
            width: 40,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/setting/newuser");
          }}
        />
      </div>
      <div className="container-div">
        <DataGrid
          onCellClick={(cell) => {
            if (cell.field === "user_name") {
              console.log("cell ", cell);
              // Custom logic for clicking the "Name" column
            }
          }}
          loading={loading}
          rows={allUsers}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          sx={{
            boxShadow: 2,

            "& .MuiDataGrid-columnHeaders": {
              position: "sticky",
              top: 0,
              zIndex: 1100,
              color: "black",
              "& .MuiDataGrid-menuIcon": {
                visibility: "visible",
                width: "auto",
              },
            },
            "& .MuiDataGrid-cell": {
              overflow: "hidden",
            },

            "& .MuiDataGrid-cell[data-field='user_name']": {
              color: Colors.LinkColor,
              cursor: "pointer",
            },
            "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
              outline: "none",
              border: "none",
            },
            "& .MuiDataGrid-filterPanel": {
              padding: "16px !important",
              margin: 10,
              backgroundColor: "red !important", // Adjust the padding as needed
            },
          }}
          componentsProps={{
            toolbar: {
              showQuickFilter: true,
              quickFilterProps: { debounceMs: 500 },
            },
          }}
        />
      </div>
    </Container>
  );
};

export default UserListTable;
