// src/App.js
import React, { useEffect, useState } from "react";
import FixedNavbar from "./components/navbar/Navbar";
import Sidebar from "./components/sidebar/SideBar";
import MainContent from "./components/mainpage/MainContent";
import "./App.css";
import SignIn from "./components/signin/SignIn";
import { ToastContainer, toast, Bounce } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setLoginStatus } from "./redux/slices/slice";
import { setPermisions } from "./redux/slices/permisionSlice";

const App = () => {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);

  // const [loginStatus, setLoginStatus] = useState(false);

  useEffect(() => {
    const login = localStorage.getItem("login");
    const permisions = localStorage.getItem("permisions");
    // setLoginStatus(login);
    dispatch(setLoginStatus(login));
    dispatch(setPermisions(permisions));
  });
  return (
    <div>
      {state.auth.loginStatus ? (
        <React.Fragment>
          <FixedNavbar />
          <Sidebar />
          <MainContent />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <SignIn />
        </React.Fragment>
      )}

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </div>
  );
};

export default App;
