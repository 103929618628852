import React, { useEffect, useState } from "react";
import { Button, Container, Table } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { Outlet, Route, Routes, useNavigate } from "react-router-dom";
import { Colors } from "../../constant/Constant";
import { IoIosAddCircle, IoMdAddCircle } from "react-icons/io";
import { MdOutlineAddCircle } from "react-icons/md";
import { getAllHospitalApi } from "./hospitalApi/hospitalApi";
import { DataGrid } from "@mui/x-data-grid";
import "../../styles/tableDataGrid.css";
import DotLoader from "../loader/DotLoader";

const columns = [
  {
    field: "hospital_name",
    headerName: "HOSPITAL",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
    // hide: false,
  },
  {
    field: "customer_name",
    headerName: "DOCTOR",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
  {
    field: "hospital_type_name",
    headerName: "TYPE",

    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
  {
    field: "hospital_city_name",
    headerName: "CITY",

    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
  {
    field: "hospital_area_name",
    headerName: "AREA",
    type: "email",
    flex: 1,
    minWidth: 150,
    headerClassName: "headercolor",
  },
];
const Hospital = () => {
  const navigate = useNavigate();
  const [allHospitals, setAllHospitals] = useState([]);
  const [loader, setLoader] = useState(false);

  const handleRowClick = (id) => {
    navigate(`/updatecustomer/${id}`);
  };

  useEffect(() => {
    initialFunction();
  }, []);

  const initialFunction = () => {
    getAllCustomers();
  };

  const getAllCustomers = async () => {
    setLoader(true);
    const responce = await getAllHospitalApi();
    setLoader(false);
    if (responce.success) {
      const filterData = responce.data.map((item) => {
        return {
          ...item,
          id: item.hospital_id,
        };
      });
      setAllHospitals(filterData);
      console.log("Hospitals---", responce);
    }
  };

  const handleHospital = (id) => {
    navigate(`/hospital/view/${id}`, { state: { data: id } });
  };

  return (
    <Container>
      {loader ? (
        <DotLoader />
      ) : (
        <div>
          <div className="d-flex justify-content-end mb-5 mt-2">
            <MdOutlineAddCircle
              style={{
                color: Colors.OrthoColor,
                height: 40,
                width: 40,
                cursor: "pointer",
              }}
              onClick={() => {
                navigate("/hospital/newhospital");
              }}
            />
          </div>

          <h3 className="mb-4">Hospitals</h3>
          <DataGrid
            onCellClick={(cell) => {
              console.log(cell.field);
              if (cell.field === "hospital_name") {
                console.log("cell ", cell);
                handleHospital(cell.id);
                // Custom logic for clicking the "Name" column
              }
            }}
            // loading={loader}
            rows={allHospitals}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            sx={{
              boxShadow: 2,

              "& .MuiDataGrid-columnHeaders": {
                position: "sticky",
                top: 0,
                zIndex: 1100,
                color: Colors.BlackLight,
                boxShadow: "3px 3px 10px -3px #dddddd",
                "& .MuiDataGrid-menuIcon": {
                  visibility: "visible",
                  width: "auto",
                },
                "& .MuiDataGrid-sortIcon": {
                  opacity: 1,
                  visibility: "visible",
                },

                fontFamily: "'Roboto', sans-serif",
              },
              "& .MuiDataGrid-cell": {
                overflow: "hidden",
              },

              "& .MuiDataGrid-cell[data-field='hospital_name']": {
                color: Colors.LinkColor,
                cursor: "pointer",
              },
              "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
                outline: "none",
                border: "none",
              },
            }}
          />
        </div>
      )}
      <Outlet></Outlet>
    </Container>
  );
};

export default Hospital;
