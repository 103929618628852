// src/components/Sidebar.js
import React, { useEffect, useState } from "react";
import { ListGroup, Collapse } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import {
  FaAngleDown,
  FaHome,
  FaBox,
  FaUserFriends,
  FaUserMd,
  FaHospital,
  FaClipboardList,
} from "react-icons/fa";
import "./Sidebar.css";
import { MdOutlineInventory } from "react-icons/md";
import { useSelector } from "react-redux";

const Sidebar = () => {
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const [collapsed, setCollapsed] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("Home"); // Track selected menu

  const [inventryDropdown, setInventryDropdown] = useState(false);
  const [viewOrderShow, setViewOrderShow] = useState(true);

  const toggleInventryDropdown = () => {
    setInventryDropdown(!inventryDropdown);
  };
  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleMenuClick = (menu) => {
    setSelectedMenu(menu);
    if (menu === "Customers") {
      toggleDropdown();
    }
    if (menu === "Inventry") {
      console.log(menu);

      toggleInventryDropdown();
    }
  };

  useEffect(() => {
    console.log("state----------------------------------------", state);
  }, []);

  return (
    <div
      className={` flex-column position-fixed ${collapsed ? "collapsed" : ""}`}
      style={{
        height: "100%", // Full height
        width: "250px",
        transition: "width 0.3s",
        backgroundColor: "#343a40", // Sidebar background color
        top: "0",
        paddingTop: "50px", // Offset to avoid overlap with the navbar
      }}
    >
      {/* <div className="d-flex justify-content-center align-items-center">
        <h3 style={{ color: "white" }}>Ortholink</h3>
      </div> */}
      {state.viewOrders.viewOrder ? (
        <ListGroup className="mt-4 sidebar-list-group">
          <ListGroup.Item
            action
            className={`side-menu-item ${
              selectedMenu === "OrderInfo" ? "active" : ""
            }`}
            onClick={() => {
              handleMenuClick("OrderInfo");
              navigate("/orders/view-order/order-info");
            }}
          >
            <FaHome className="mx-2" />
            Order Info
          </ListGroup.Item>
          <hr />
          <ListGroup.Item
            action
            className={`side-menu-item ${
              selectedMenu === "OrderTrack" ? "active" : ""
            }`}
            onClick={() => {
              handleMenuClick("OrderTrack");
              navigate("/orders/view-order/order-track");
            }}
          >
            <FaHome className="mx-2" />
            Order Track
          </ListGroup.Item>
          <hr />

          <ListGroup.Item
            action
            className={`side-menu-item ${
              selectedMenu === "OrderStatus" ? "active" : ""
            }`}
            onClick={() => {
              handleMenuClick("OrderStatus");
              navigate("/orders/view-order/order-status");
            }}
          >
            <FaHome className="mx-2" />
            Order Status
          </ListGroup.Item>
          <hr />

          <ListGroup.Item
            action
            className={`side-menu-item ${
              selectedMenu === "DC" ? "active" : ""
            }`}
            onClick={() => {
              handleMenuClick("DC");
              navigate("/orders/view-order");
            }}
          >
            <FaHome className="mx-2" />
            DC
          </ListGroup.Item>
          <hr />
          <ListGroup.Item
            action
            className={`side-menu-item ${
              selectedMenu === "ReturnDC" ? "active" : ""
            }`}
            onClick={() => {
              handleMenuClick("ReturnDC");
              navigate("/orders/view-order");
            }}
          >
            <FaHome className="mx-2" />
            Return DC
          </ListGroup.Item>
          <hr />
        </ListGroup>
      ) : (
        <ListGroup className="mt-4 sidebar-list-group">
          <ListGroup.Item
            action
            className={`side-menu-item ${
              selectedMenu === "Home" ? "active" : ""
            }`}
            onClick={() => {
              handleMenuClick("Home");
              navigate("/");
            }}
          >
            <FaHome className="mx-2" />
            Home
          </ListGroup.Item>
          <hr />
          <ListGroup.Item
            action
            className={`side-menu-item ${
              selectedMenu === "Orders" ? "active" : ""
            }`}
            onClick={() => {
              handleMenuClick("Orders");
              navigate("/orders/home");
            }}
          >
            <FaClipboardList className="mx-2" /> Orders
          </ListGroup.Item>
          <hr />
          <ListGroup.Item
            action
            className={`side-menu-item ${
              selectedMenu === "Products" ? "active" : ""
            }`}
            onClick={() => {
              handleMenuClick("Products");
            }}
          >
            <FaBox className="mx-2" /> Products
          </ListGroup.Item>

          <hr />
          <ListGroup.Item
            action
            onClick={() => {
              handleMenuClick("Customers");
            }}
            className={`side-menu-item d-flex justify-content-between align-items-center ${
              selectedMenu === "Customers" ? "active" : ""
            }`}
          >
            <div>
              <FaUserFriends className="mx-2" /> Customers
            </div>
            <FaAngleDown />
          </ListGroup.Item>
          <hr />
          <Collapse in={dropdownOpen}>
            <div className="submenu-container">
              <ListGroup>
                <ListGroup.Item
                  action
                  className={`sub-menu-item text-white ${
                    selectedMenu === "Doctors" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleMenuClick("Doctors");
                    navigate("customer");
                  }}
                >
                  <FaUserMd className="mx-2" /> Doctors
                </ListGroup.Item>

                <ListGroup.Item
                  action
                  className={`sub-menu-item ${
                    selectedMenu === "Hospitals" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleMenuClick("Hospitals");
                    navigate("hospital");
                  }}
                >
                  <FaHospital className="mx-2" /> Hospitals
                </ListGroup.Item>
                <hr />
              </ListGroup>
            </div>
          </Collapse>
          <ListGroup.Item
            action
            // className={`side-menu-item ${
            //   selectedMenu === "Inventry" ? "active" : ""
            // }`}
            onClick={() => {
              handleMenuClick("Inventry");
            }}
            className={`side-menu-item d-flex justify-content-between align-items-center ${
              selectedMenu === "Inventry" ? "active" : ""
            }`}
          >
            {/* <FaBox className="mx-2" /> Inventry */}
            <div>
              <MdOutlineInventory className="mx-2" />
              Inventry
            </div>
            <FaAngleDown />
          </ListGroup.Item>
          <hr></hr>
          <Collapse in={inventryDropdown}>
            <div className="submenu-container">
              <ListGroup>
                <ListGroup.Item
                  action
                  className={`sub-menu-item text-white ${
                    selectedMenu === "Stock" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleMenuClick("Stock");
                    navigate("/stock");
                  }}
                >
                  <FaUserMd className="mx-2" /> Stock
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  className={`sub-menu-item text-white ${
                    selectedMenu === "Stock Adjustment" ? "active" : ""
                  }`}
                  onClick={() => {
                    handleMenuClick("Stock Adjustment");
                    navigate("stock-adjustment");
                  }}
                >
                  <FaUserMd className="mx-2" /> Stock Adjustment
                </ListGroup.Item>
                <hr />
              </ListGroup>
            </div>
          </Collapse>
        </ListGroup>
      )}
    </div>
  );
};

export default Sidebar;
