import { BASE_URL } from "../../constant/Constant";

const userSignInApi = async (loginData) => {
  try {
    const responce = await fetch(`${BASE_URL}/user-service/users/signin`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    })
      .then((res) => res.json())
      .then((data) => {
        return data;
      })
      .catch((err) => {
        console.log("Api Error", err);
      });

    localStorage.setItem("token", "Bearer " + responce.jwtResponse.token);
    localStorage.setItem(
      "permisions",
      JSON.stringify(responce.customResponse.modules)
    );
    localStorage.setItem("username", responce.jwtResponse.username);
    localStorage.setItem("userid", responce.jwtResponse.id);

    localStorage.setItem("roleid", responce.customResponse.role_id);
    localStorage.setItem("rolename", responce.customResponse.role_name);
    localStorage.setItem("login", true);
    return responce;
  } catch (error) {
    console.log(error);
  }
};

export { userSignInApi };
