import React, { useEffect, useState } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  InputGroup,
  Tab,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaMinus } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "./CustomerCreate.css";
import { getCreateCustomerDropdownListApi } from "./customersApi/customerProfileApi";
import { getAllHospitalApi } from "../hospital/hospitalApi/hospitalApi";
import Select from "react-select";
import { Bounce, ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ListGroup, ListGroupItem } from "reactstrap";
import { FaDeleteLeft } from "react-icons/fa6";
import { getAllAreaApi } from "../../api/locations/locationsApi";
import { newCustomerCreateApi } from "./customersApi/customerCreateApi";

const inputData = { label: "", value: "" };

const NewCustomerForm = () => {
  const navigate = useNavigate();
  const [areas, setArea] = useState([]);
  const [hospitals, setHospitals] = useState([{ id: 1, name: "" }]);
  const [search, setSearch] = useState("");
  const [filteredAreas, setFilteredAreas] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState({
    customerPaymentStatuses: [],
    activities: [],
    customerTypes: [],
    customerPaymentTypes: [],
  });

  const [formData, setFormData] = useState({
    salutation: "",
    firstName: "",
    lastName: "",
    phoneNumber: "",
    alternatePhoneNumber: "",
    area: "",
    specialization: "",

    comments: "",
  });

  const [errors, setErrors] = useState({});

  const [customerPaymentStatusDropdown, setCustomerPaymentStatusDropdown] =
    useState([]);
  const [customerDropdownList, setCustomerDropdownList] = useState({});
  const [allHospitals, setAllHospitals] = useState({});

  const [selectHospitalsList, setSelectHospitalsList] = useState([]);
  const [selectedHospital, setSelectedHospital] = useState(null);
  const [allAreas, setAllAreas] = useState(inputData);
  const [selectedArea, setSelectedArea] = useState({});

  const [customerTypeAll, setCustomerTypeAll] = useState([]);
  const [selectedCustomerType, setSelectedCustomerType] = useState({
    inputData,
  });

  const [paymentType, setPaymentType] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState({ inputData });

  const [activityStatus, setActivityStatus] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState({ inputData });

  const [customerPaymentStatus, setCustomerPaymentStatus] = useState([]);

  const [selectedCustomerPaymentStatus, setSelectedCustomerPaymentStatus] =
    useState({ inputData });
  const handleChange = (e) => {
    console.log(e.target);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChangeArea = (area) => {
    console.log(area);
    setSelectedArea(area);
  };

  useEffect(() => {
    getCreateCustomerDropdownList();
    getAllHospitals();
    getAllArea();
  }, []);

  const getCreateCustomerDropdownList = async () => {
    const responce = await getCreateCustomerDropdownListApi();

    if (responce.success) {
      setCustomerDropdownList(responce.data);

      const filterCustomerTypeData = responce.data.customerTypes.map((data) => {
        return {
          label: data.customer_type_name,
          value: data.customer_type_id,
        };
      });

      const filterPaymentType = responce.data.customerPaymentTypes.map(
        (data) => {
          return {
            label: data.customer_payment_type_name,
            value: data.customer_payment_type_id,
          };
        }
      );

      const filterActivityStatus = responce.data.activities.map((data) => {
        return {
          label: data.activity_name,
          value: data.activity_id,
        };
      });

      const filterCustomerPaymentStatus =
        responce.data.customerPaymentStatuses.map((data) => {
          return {
            label: data.customer_payment_status_name,
            value: data.customer_payment_status_id,
          };
        });
      console.log(
        "---------------------------------------------------------",
        filterActivityStatus[0]
      );

      setCustomerPaymentStatus(filterCustomerPaymentStatus);
      setSelectedCustomerPaymentStatus(filterCustomerPaymentStatus[0]);

      setSelectedActivity(filterActivityStatus[0]);
      setActivityStatus(filterActivityStatus);

      setPaymentType(filterPaymentType);
      setSelectedPaymentType(filterPaymentType[0]);
      setCustomerTypeAll(filterCustomerTypeData);
      setSelectedCustomerType(filterCustomerTypeData[0]);
      console.log("-----------------------Dropdownlist-", responce);
    }
  };

  const getAllArea = async () => {
    const responce = await getAllAreaApi();
    console.log(responce);
    const filterData = responce.map((area) => {
      return {
        label: area.area_name,
        value: area.area_id,
      };
    });
    console.log("filter area-------", filterData);
    setAllAreas(filterData);
  };

  const getAllHospitals = async () => {
    const responce = await getAllHospitalApi();
    const filterData = responce.data.map((item) => ({
      value: item.hospital_id,
      label: item.hospital_name,
    }));
    setAllHospitals(filterData);
    console.log("Hospitalss-", responce);
  };

  const validateFields = () => {
    let isValid = true;
    let newErrors = {};

    if (!formData.salutation) {
      newErrors.salutation = "Customer Salutation is required";
      isValid = false;
    }
    if (!formData.firstName) {
      newErrors.firstName = "Customer First Name is required";
      isValid = false;
    }
    if (!formData.phoneNumber) {
      newErrors.phoneNumber = "Phone Number is required";
      isValid = false;
    }
    if (!selectedArea.value) {
      newErrors.area = "Area is required";
      isValid = false;
    }
    if (!formData.specialization) {
      newErrors.specialization = "Specialization is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = (e) => {
    console.log("clicked handl submit");
    e.preventDefault();
    try {
      if (!validateFields()) {
        return;
      }

      const filterHospitalList = selectHospitalsList.map((hospital) => ({
        hospital_id: hospital.value,
      }));
      const customerData = {
        customer_salutation: formData.salutation,
        customer_first_name: formData.firstName,
        customer_last_name: formData.lastName,
        customer_phone_number: formData.phoneNumber,
        customer_alternate_phone_number: formData.alternatePhoneNumber,
        customer_specialization: formData.specialization,
        customer_type_id: selectedCustomerType.value,
        customer_area_id: selectedArea.value ? selectedArea.value : 1,
        // customer_payment_type_id: selectedPaymentType.value,
        // customer_payment_status_id: selectedCustomerPaymentStatus.value,
        // activity_id: selectedActivity.value,
        customer_comments: "",
        created_by: localStorage.getItem("userid"),
        hospital_junction: filterHospitalList,
      };

      console.log("send data-------------------", customerData);
      createCustomer(customerData);
    } catch (error) {
      console.log("Catch-Handle Submit-", error);
    }
  };

  const createCustomer = async (customerData) => {
    console.log("create api called");

    const responce = await newCustomerCreateApi(customerData);
    console.log(responce);

    if (responce.success) {
      toast.success("Customer Created...");
    } else {
      toast.error("Customer Creation Error...");
    }
  };

  const handleHospitalChange = (index, e) => {
    const newHospitals = [...hospitals];
    newHospitals[index].name = e.target.value;
    setHospitals(newHospitals);
  };

  const addHospitalField = () => {
    setHospitals([...hospitals, { id: hospitals.length + 1, name: "" }]);
  };

  const removeHospitalField = (index) => {
    const newHospitals = hospitals.filter((_, i) => i !== index);
    setHospitals(newHospitals);
  };

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearch(query);

    if (query) {
      setFilteredAreas(
        areas.filter((area) => area.toLowerCase().includes(query.toLowerCase()))
      );
    } else {
      setFilteredAreas([]);
    }
  };

  const handleSelectArea = (area) => {
    setFormData({ ...formData, area });
    setSearch(area);
    setFilteredAreas([]);
  };

  const handleKeyPress = (e) => {
    const charCode = e.charCode;
    if (charCode < 48 || charCode > 57) {
      e.preventDefault();
    }
  };

  const hospitalHandleChange = (data) => {
    setSelectedHospital(data);
  };

  const handleChangeCustomerType = (data) => {
    setSelectedCustomerType(data);
  };

  const handleChangePaymentType = (data) => {
    setSelectedPaymentType(data);
    console.log(data);
  };

  const handleChangeActivityStatus = (data) => {
    setActivityStatus(data);
    console.log(data);
  };

  const handleChangeCustomerPaymentStatus = (data) => {
    setSelectedCustomerPaymentStatus(data);
  };

  // useEffect(() => {

  // }, [activityStatus]);

  const addHospital = () => {
    if (selectedHospital) {
      try {
        const exists = selectHospitalsList.some(
          (hospital) => hospital.value === selectedHospital.value
        );

        if (!exists) {
          setSelectHospitalsList([...selectHospitalsList, selectedHospital]);
          setSelectedHospital(null);
        } else {
          toast.error("Hospital already exists in the list.");
        }
      } catch (error) {
        console.error("Error adding hospital:", error);
      }
    }
  };

  const deleteHospital = (hospitalId) => {
    const filterData = selectHospitalsList.filter(
      (hospital) => hospital.value !== hospitalId
    );
    setSelectHospitalsList(filterData);
  };

  useEffect(() => {
    console.log("selectHospitalsList----", selectHospitalsList);
  }, [selectHospitalsList]);

  return (
    <Container className="my-5 p-4 rounded   custom-container">
      <Form onSubmit={handleSubmit}>
        <Row className="mb-5">
          <Col>
            <Form.Group controlId="formSalutation">
              <Form.Label>Customer Salutation</Form.Label>
              <Form.Control
                as="select"
                name="salutation"
                value={formData.salutation}
                onChange={handleChange}
                className="custom-select"
              >
                <option value="">Select Salutation</option>
                <option value="Mr.">Mr</option>
                <option value="Ms.">Ms</option>
                <option value="Dr.">Dr</option>
              </Form.Control>
              {errors.salutation && (
                <div className="text-danger">{errors.salutation}</div>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formFirstName">
              <Form.Label>Customer First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter first name"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="custom-input"
              />
              {errors.firstName && (
                <div className="text-danger">{errors.firstName}</div>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formLastName">
              <Form.Label>Customer Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter last name"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="custom-input"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-5">
          <Col>
            <Form.Group controlId="formPhoneNumber">
              <Form.Label>Phone Number</Form.Label>
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">+</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Enter phone number"
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  className="custom-input"
                />
              </InputGroup>
              {errors.phoneNumber && (
                <div className="text-danger">{errors.phoneNumber}</div>
              )}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formAlternatePhoneNumber">
              <Form.Label>Alternate Phone Number</Form.Label>
              <InputGroup>
                <InputGroup.Text id="inputGroupPrepend">+</InputGroup.Text>
                <Form.Control
                  type="text"
                  placeholder="Enter alternate phone number"
                  name="alternatePhoneNumber"
                  value={formData.alternatePhoneNumber}
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  className="custom-input"
                />
              </InputGroup>
            </Form.Group>
          </Col>
        </Row>

        {/* <Row></Row> */}
        <Row className="mb-5 w-50">
          <Col>
            <Form.Group controlId="formBranch" className="mb-3">
              <Form.Label>Area</Form.Label>

              <Select options={allAreas} onChange={handleChangeArea} />
              {errors.area && <div className="text-danger">{errors.area}</div>}
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Customer Specialization</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Specialization"
                name="specialization"
                value={formData.specialization}
                onChange={handleChange}
                className="custom-input "
              />
              {errors.specialization && (
                <div className="text-danger">{errors.specialization}</div>
              )}
            </Form.Group>
          </Col>
        </Row>

        <Form.Group controlId="formHospitals" className="px-2 mb-5">
          <Row>
            <Col>
              <Form.Label>Hospitals</Form.Label>
              {hospitals.map((hospital, index) => (
                <div
                  key={hospital.id}
                  className="d-flex mb-3 align-items-center"
                >
                  <Select
                    className="w-50"
                    value={selectedHospital}
                    options={allHospitals}
                    onChange={(value) => {
                      hospitalHandleChange(value);
                    }}
                  />
                  <Button
                    variant="success"
                    onClick={addHospital}
                    className="mx-2"
                  >
                    <FaPlus />
                  </Button>
                </div>
              ))}
            </Col>
            <Col>
              <Form.Group controlId="formCustomerType">
                <Form.Label>Customer Type</Form.Label>

                <Select
                  value={selectedCustomerType}
                  options={customerTypeAll}
                  onChange={handleChangeCustomerType}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col>
              <ListGroup className="w-25">
                {selectHospitalsList.length > 0 &&
                  selectHospitalsList.map((hospital, index) => (
                    <ListGroupItem
                      key={hospital.value}
                      className="px-5 d-flex justify-content-between align-items-center"
                    >
                      {index + 1}. {hospital.label}{" "}
                      <FaDeleteLeft
                        color="red"
                        cursor="pointer"
                        onClick={() => deleteHospital(hospital.value)}
                      />
                    </ListGroupItem>
                  ))}
              </ListGroup>
            </Col>
          </Row>
        </Form.Group>

        {/* <Row className="mb-5">
          
          <Col>
            <Form.Group controlId="formPaymentType">
              <Form.Label>Payment Type</Form.Label>

              <Select
                value={selectedPaymentType}
                options={paymentType}
                onChange={handleChangePaymentType}
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group controlId="formActivityStatus">
              <Form.Label>Activity Status</Form.Label>

              <Select
                value={selectedActivity}
                options={activityStatus}
                onChange={handleChangeActivityStatus}
              />
            </Form.Group>
          </Col>
        </Row> */}
        {/* <Row>
          <Col>
            <Form.Group controlId="formActivityStatus">
              <Form.Label>Customer Payment Status</Form.Label>

              <Select
                className="w-25"
                value={selectedCustomerPaymentStatus}
                options={customerPaymentStatus}
                onChange={handleChangeCustomerPaymentStatus}
              />
            </Form.Group>
          </Col>
        </Row> */}

        <div className="text-center mt-5">
          <Button
            variant="primary"
            type="submit"
            className="px-5 custom-button"
          >
            Create
          </Button>
        </div>
      </Form>
      <ToastContainer />
    </Container>
  );
};

export default NewCustomerForm;
